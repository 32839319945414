import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import { dybProjectFilters } from "../components/Listing/config";
import DybProjectsResultsArea from "../components/Listing/DybProjectsResultsArea";
import Listing from "../components/Listing/Listing";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { SanityDybProject } from "../model/impact";
import { SanityLessonsPage } from "../model/lessons";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import { getSanitizedLocationId } from "../utils/country";
import { createRNG, shuffleArray } from "../utils/ssr-random";

export const pageQuery = graphql`
  query DybProjectListingPage(
    $_id: String!
    $language: String
    $navLanguage: String
  ) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    listings: sanityDybProjectListingPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      _rawSideBarText(resolveReferences: { maxDepth: 4 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
    }
    content: allSanityDybProject(filter: { language: { eq: $language } }) {
      nodes {
        ...DybProjectCard
        globalGoals {
          _id
          number
          name
        }
      }
    }
    campaigns: allSanityCampaign(
      filter: { activeSiteAreas: { in: "impact" } }
    ) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const DybProjectListingPage = ({
  pageContext,
  data: { menus, campaigns, content, listings },
  location
}: DybProjectListingPageProps) => {
  const { _rawSideBarText, title } = listings;
  (content.nodes as SanityDybProject[]).forEach(
    p => (p.location._id = getSanitizedLocationId(p.location._id))
  );
  const rng = createRNG();
  const contents = shuffleArray(content.nodes as SanityDybProject[], rng);
  return (
    <PageLayout
      siteArea={SiteArea.IMPACT}
      metadata={{
        title,
        alternates: pageContext.alternates,
        page: listings
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <Listing
        page={listings}
        campaigns={campaigns.nodes}
        title={title}
        config={dybProjectFilters}
        sideBarText={_rawSideBarText}
        contents={contents}
        renderRows={DybProjectsResultsArea}
      />
    </PageLayout>
  );
};

export default DybProjectListingPage;

interface DybProjectListingPageProps {
  data: {
    menus: GlobalMenus;
    campaigns: { nodes: SanityCampaign[] };
    listings: SanityLessonsPage;
    content: any;
    thirdPartyResources: any;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
