import React from "react";
import { SanityDybProject } from "../../model/impact";
import DybProjectCard from "../DybProjectCard/DybProjectCard";
import { Col, Row } from "../Grid";
import styles from "./Listing.module.scss";

const DybProjectsResultsArea = ({ results }: DybProjectResultsArea) => {
  return (
    <Row>
      {results.map(p => (
        <Col key={p._id} xs={6} sm={6} md={4} className={styles.cardListingCol}>
          <DybProjectCard project={p} className={styles.cardListingCard} />
        </Col>
      ))}
    </Row>
  );
};

interface DybProjectResultsArea {
  results: SanityDybProject[];
}

export default DybProjectsResultsArea;
